// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ascii-tsx": () => import("./../src/pages/ascii.tsx" /* webpackChunkName: "component---src-pages-ascii-tsx" */),
  "component---src-pages-code-demos-tsx": () => import("./../src/pages/code-demos.tsx" /* webpackChunkName: "component---src-pages-code-demos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-open-source-tsx": () => import("./../src/pages/open-source.tsx" /* webpackChunkName: "component---src-pages-open-source-tsx" */),
  "component---src-pages-tech-blog-tsx": () => import("./../src/pages/tech-blog.tsx" /* webpackChunkName: "component---src-pages-tech-blog-tsx" */),
  "component---src-pages-travel-blog-tsx": () => import("./../src/pages/travel-blog.tsx" /* webpackChunkName: "component---src-pages-travel-blog-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-project-tsx": () => import("./../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

